import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

class Block4 extends Component {
    render() {
        return (
            <Box_home id="thf">
            <Container>
            <Nav>
              <ul>
                  <li><a href="/"><img src="./1sol.svg" alt="" /></a></li>
                  <li><a href="/"><img src="./2sol.svg" alt="" /></a></li>
                  <li><a href="/"><img src="./3sol.svg" alt="" /></a></li>
                  <li><a href="/"><img src="./4sol.svg" alt="" /></a></li>
                  <li><a href="/"><img src="./5sol.svg" alt="" /></a></li>
                  <li><a href="/"><img src="./6sol.svg" alt="" /></a></li>
                  <li><a href="/"><img src="./7sol.svg" alt="" /></a></li>
              </ul>
            </Nav>
            <div className="gkl40">
              <img src="./bo04.svg" alt="" />
            </div>
            <div className="gkl40">
              <img src="./iop.png" alt="" />
            </div>


            </Container>
          </Box_home>
        );
    }
}




const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    padding: 65px 0;
    z-index:1;
    p{
      color: #A0AEE5;
    }

    @media (max-width: 770px) {
      padding:15px 0;
    }
    .gkl40{
      text-align:center;
      img{
        max-width:95%;
      }
    }
`;



const H3tiel = styled.h3`
  font-size: 56px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #ffffff;
  text-align:center;
  font-family: "Kalam", cursive;
`;



const Nav = styled.div`
  position: relative;
  margin-top: 25px;
  text-align:center;
  margin-bottom:15px;

  ul{
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    margin-bottom: 2rem;

    li{
      max-width:100px;
      width:100%;

      a{
        :hover{
          opacity:.5;
        }
      }
    }
  }

  @media (max-width: 770px) {

  }
`;



export default Block4;
